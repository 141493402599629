@import "../scss/values";

.help-features {
    .item {
        text-align: center;
    }

    .material-icons {
        display: block;
        font-size: 32px;
        color: $accent_500;
    }
}