@import "../values";

.breadcrumbs {

    a:not(:last-of-type)::after,
    button.link:not(:last-of-type)::after {
        display: inline-block;
        content: '/';
        color: $text_200;
        margin: 0 8px;
    }
}
