@import "../scss/values";

.estimate-type-radio {
    label {
        display: block;
        text-align: center;
        border: solid 1px $neutral_600;
        cursor: pointer;
        margin-bottom: 0;
        border-radius: 8px;
        font-size: 16px;
        padding: 12px;
        
        .material-icons {
            display: block;
            font-size: 32px;
            margin: 0 0 12px 0;
        }
    }

    input {
        display: none;
    }

    input:checked + label {
        background: $neutral_400;
    }
}

@media (min-width: $break_md) {
    .estimate-type-radio label {
        font-size: 22px;
        padding: 30px;
    }
}

.address-autocomplete {
    position: relative;

    input.has-suggestions:focus {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .suggestions {
        display: none;
        position: absolute;
        top: 50px;
        right: 0;
        left: 0;
        background: #fff;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .1);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;
        z-index: 98;

        .item {
            padding: 8px 16px;
            cursor: pointer;
            transition: background .2s ease;
            font-size: 14px;
        }

        .item:hover {
            background: $neutral_400;
        }

        .item:not(:last-of-type) {
            border-bottom: solid 1px $neutral_600;
        }
    }

    input:not(:disabled):focus + .suggestions,
    input:not(:disabled):active + .suggestions,
    .suggestions:active {
        display: block;
    }

    .loader {
        display: none;
        width: 22px;
        height: 22px;
        position: absolute;
        top: 14px;
        right: 14px;
        border: 2px solid $primary_500;
        border-bottom-color: transparent;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    .loader.visible {
        display: inline-block;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
