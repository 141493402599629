@import "../scss/values";

.modal-wrap {
    position: fixed;
    z-index: 100;

    .overlay {
        position: absolute;
        background: rgba(0, 0, 0, .6);
    }

    &, .overlay {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .modal {
        position: absolute;
        z-index: 101;
        background: #fff;
        box-shadow: 8px 8px 8px 0 rgba(0, 0, 0, .1);
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        width: 90%;
        max-width: 760px;
        max-height: 85%;

        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .modal > .header {
        padding: 16px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $neutral_400;
        border-bottom: solid 1px $neutral_600;

        .title {
            font-size: 22px;
            line-height: 26px;
            font-weight: 400;
            flex-grow: 1;
        }

        .close {
            background: transparent;
            border: 0;
            outline: 0;
            cursor: pointer;
            color: $text_200;

            &:hover {
                color: $text_500;
            }
        }
    }

    .modal > .content {
        padding: 20px;
        flex-grow: 1;
        overflow-y: auto;
    }
}

@media (min-width: $break_md) {
    .modal-wrap {
        .modal > .header {
            padding: 20px 30px;

            .title {
                font-size: 28px;
                line-height: 34px;
            }
        }

        .modal > .content {
            padding: 30px;
        }
    }
}