@import "../scss/values";

.autocomplete {
    position: relative;

    input.has-suggestions:focus {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .suggestions {
        display: block;
        position: absolute;
        top: 50px;
        right: 0;
        left: 0;
        background: #fff;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, .1);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        overflow: hidden;
        z-index: 101;

        .item {
            padding: 8px 16px;
            cursor: pointer;
            transition: background .2s ease;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            flex-direction: column;

            .sub-label {
                color: $text_200;
                text-transform: uppercase;
            }
        }

        .item.focused {
            background: $neutral_400;
        }

        .item:not(:last-of-type) {
            border-bottom: solid 1px $neutral_600;
        }
    }

    .loader {
        display: none;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 10px;
        border: 2px solid $primary_500;
        border-bottom-color: transparent;
        border-radius: 50%;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
    }

    .loader.visible {
        display: inline-block;
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
