@import "../scss/values";

#head {
    position: fixed;
    top: 0; right: 0; left: 0;
    box-shadow: 0 4px 2px 0 rgba(0, 0, 0, .1);
    background: #fff;
    z-index: 100;

    &, .wrapper {
        height: $header_height_sm;
    }

    .wrapper {
        display: flex;
        align-items: center;
    }
}

@media(min-width: $break_md) {
    #head, #head .wrapper {
        height: $header_height_md;
    }
}

#open-nav {
    height: 100%;
    background: transparent;
    border: 0;
    padding: 0 12px;
}

@media(min-width: $break_md) {
    #open-nav {
        display: none;
    }
}

#logo {
    padding: 0;
    background-color: $neutral_100;
    border: none;
    cursor: pointer;
}

#logo img {
    display: block;
    height: $logo_height_sm;
}

@media(min-width: $break_md) {
    #logo img {
        height: $logo_height_md;
    }
}

#search {
    flex-grow: 1;

    input {
        width: 100%;
        height: $search_height_sm;
    }

    .autocomplete .suggestions {
        top: $search_height_sm;
    }
}

@media(min-width: $break_md) {
    #search {
        input {
            height: $search_height_md;
        }

        .autocomplete .suggestions {
            top: $search_height_md;
        }
    }
}

#nav {
    position: fixed;
    top: 0; right: 20%; bottom: 0; left: 0;
    background: #fff;
    z-index: 101;
    transition: transform .2s ease;
    transform: translateX(-100%);

    &.visible {
        box-shadow: 10px 0 110px 0 rgba(0, 0, 0, .4);
        transform: translateX(0);
    }

    button.estimate {
        height: 40px;
        line-height: 36px;
    }

    button.link {
        display: block;
        width: 100%;
        text-decoration: none;
        text-align: left;;
        color: $text_500;
        padding: 12px 20px;
        border-bottom: solid 1px $neutral_500;
        transition: border-color .2s ease;
        font-weight: normal;

        &:hover {
            border-color: $primary_500;
        }
    }
}

@media(min-width: $break_md) {
    #nav {
        position: static;
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;

        &, &.visible {
            transform: translateX(0);
            box-shadow: none;
        }

        button.link {
            padding: 4px 0;
            width: auto;
            float: left;

            &:hover {
                border-color: $primary_500;
            }
        }
    }
}

#close-nav {
    width: 100%;
    height: $header_height_sm;
    background: $neutral_500;
    border: 0;
}

@media(min-width: $break_md) {
    #close-nav {
        display: none;
    }
}

@keyframes loaderAnimation {
    0% {
        transform:translateX(0) scaleX(0);
    }

    40% {
        transform:  translateX(0) scaleX(0.4);
    }

    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
}

#loader {
    height: 4px;
    background: $primary_100;

    .value {
        width: 100%;
        height: 100%;
        background: $primary_500;
        animation: loaderAnimation 1s infinite linear;
        transform-origin: 0% 50%;
    }
}
