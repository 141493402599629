@import "../scss/values";

#info-box {
    position: absolute;
    top: auto;
    right: 12px;
    bottom: 12px;
    left: 12px;
    background: #fff;
    box-shadow: 0 8px 8px 4px rgba(0, 0, 0, .1);
    border-radius: 8px;
    padding: 20px;
    z-index: 99;

    .heading {
        display: flex;

        .title {
            flex-grow: 1;

            .name {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 4px;

                small {
                    font-size: 14px;
                    vertical-align: middle;
                    color: $text_200;
                    margin-left: 8px;
                }
            }

            .breadcrumbs {
                font-size: 14px;
            }
        }

        .chart-button {
            padding: 0;
            width: 36px;
            height: 36px;

            span {
                font-size: 26px;
                margin: 0;
            }
        }
    }

    .value {
        text-align: center;
        font-size: 18px;
        line-height: 22px;

        .material-icons {
            font-size: 24px;
            color: $accent_800;
        }
    }

    .scale {
        width: 100%;
        height: 18px;
        background: linear-gradient(90deg, rgba(0, 255, 0, .4) 0%, rgba(255, 127, 0, .4) 50%, rgba(255, 0, 0, .4) 100%);
        border-radius: 4px;
    }

    #history {
        margin-top: 64px;

        font-size: 20px;

        .section-name {
            margin-bottom: 24px;
        }

        #history-chart {
            height: 300px;
        }
    }

}

@media(min-width: $break_md) {
    #info-box {
        top: $header_height_md + 30px;
        right: 30px;
        bottom: auto;
        left: auto;
        padding: 30px;
        width: 440px;

        .heading {
            .title {
                .name {
                    font-size: 36px;
                    line-height: 42px;

                    small {
                        font-size: 22px;
                    }
                }

                .breadcrumbs {
                    font-size: 16px;
                }
            }

            .chart-button {
                width: 48px;
                height: 48px;

                span {
                    font-size: 24px;
                    line-height: 38px;
                }
            }
        }


        .value {
            font-size: 24px;
            line-height: 28px;;

            .material-icons {
                font-size: 32px;
            }
        }
    }
}
