@import "../scss/values";

#geolocate {
    position: absolute;
    top: $header_height_sm + 10px;
    right: 10px;
    bottom: auto;
    left: auto;
    z-index: 98;

    .button {
        height: 50px;
        width: 50px;
        padding: 0;

        .material-icons {
            font-size: 24px;
            line-height: 42px;
            margin: 0;
        }
    }
}

@media(min-width: $break_md) {
    #geolocate {
        top: auto;
        right: 30px;
        bottom: 40px;
        left: auto;

        .button {
            height: 64px;
            width: 64px;

            .material-icons {
                font-size: 28px;
                line-height: 56px;
            }
        }
    }
}
