@import "../scss/values";

#map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    .location-marker {
        background-image: url('../marker-location.png');
        background-size: cover;
        width: 32px;
        height: 32px;
        cursor: pointer;
    }

    .mapboxgl-popup {
        z-index: 99;
        font-family: $font_primary;
    }

    .mapboxgl-popup-close-button {
        font-size: 20px;
    }

    .mapboxgl-popup-content {
        box-shadow: 0 8px 8px 4px rgba(0, 0, 0, .1);
        border-radius: 8px;
    }

    .sale-popup {
        font-size: 16px;
        letter-spacing: 1px;
        padding: 8px;

        .heading {
            display: flex;
            flex-direction: column;

            font-size: 22px;
            white-space: nowrap;

            small {
                margin-top: 4px;
                font-size: 14px;
            }
        }

        .content {
            text-align: center;

            .property-values {
                display: flex;
                justify-content: center;

                margin: 12px 36px 24px 36px;

                .item {
                    display: flex;
                    flex-direction: column;

                    span {
                        color: $accent_500;
                        font-size: 32px;
                        margin-bottom: 4px;
                    }
                }

                .item:not(:last-child) {
                    margin-right: 42px;
                }
            }

            .sale-values {
                display: flex;
                flex-direction: column;

                font-size: 24px;

                small {
                    margin-top: 8px;
                    font-size: 16px;
                }
            }
        }

        .controls {
            margin-top: 16px;
            display: flex;

            span {
                margin: 0 auto;
            }
        }
    }
}

@media (min-width: $break_md) {
    #map .mapboxgl-popup {
        z-index: 98;
    }
}