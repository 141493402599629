@import "../scss/values";

#filter {
    position: absolute;
    top: $header_height_sm + 10px;
    left: 10px;
    z-index: 98;

    .button {
        height: 50px;
        width: 50px;
        padding: 0;

        .material-icons {
            font-size: 24px;
            line-height: 42px;
            margin: 0;
        }
    }

    .dropdown {
        background: #fff;
        box-shadow: 0 8px 8px 4px rgba(0, 0, 0, .1);
        border-radius: 8px;
        padding: 12px;

        label, label .material-icons {
            font-size: 14px;
        }

        select {
            height: 40px;
        }
    }
}

@media (min-width: $break_md) {
    #filter {
        top: $header_height_md + 30px;
        left: 30px;

        .dropdown {
            width: 500px;
            padding: 12px 16px;
        }
    }
}
