@import "values";

* {
    box-sizing: border-box;
}

::selection {
    background: rgba($primary_500, .4);
    color: $text_500;
}

html, body {
    width: 100%;
    height: 100%;
}

body {
    font-family: $font_primary;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
    margin: 0;
}

h1 {
    font-size: 36px;
    line-height: 42px;
}

a {
    color: $accent_600;
    outline: none;
    text-decoration: none;
}

a:hover,button.link:hover {
    text-decoration: underline;
    cursor: pointer;
}

ul, ol {
    padding-left: 20px;
}

ul {
    list-style: circle;
}

ul li:not(:last-of-type) {
    margin-bottom: 12px; 
}

button.link {
    border: none;
    background: none;
    color: $accent_600;
    padding: 0;

    &:disabled {
        color: $text_100;

        &:hover {
            cursor: default;
            text-decoration: none;
        }
    }
}

.material-icons {
    position: relative;
    top: 3px;
}
