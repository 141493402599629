@import "../values";

form {
    @include clearfix;
    margin: 0;

    .button[type="submit"]:first-of-type {
        float: right;
    }

    & > .error {
        margin-bottom: 16px;
    }

    .input-group {
        @include clearfix;
        margin-bottom: 20px;
    }
}

label, input, select {
    display: block;
    width: 100%;
}

label {
    margin-bottom: 8px;

    span {
        color: darken($accent_500, 10);
        margin-right: 10px;
    }

    small {
        text-transform: none;
        font-weight: normal;
    }
}

input, select, textarea {
    font-size: 14px;
    height: 50px;
    padding: 0 16px;
    border: solid 2px $neutral_600;
    border-radius: 8px;
    transition: border .2s ease;
    background: $neutral_300;
    color: $text_700;
    font-family: $font_primary, 'Material Icons';

    &:focus, &:active {
        outline: none;
        border: solid 2px $primary_500;
    }
}

textarea {
    padding: 10px;
    resize: vertical;
    width: 100%;
    min-height: 200px;
}

input:disabled, select:disabled, textarea:disabled {
    background: darken($neutral_500, 4);
}

textarea {
    font-family: $font_primary;
    font-size: 14px;
    line-height: 26px;
}

input[type="checkbox"] {
    float: left;
    width: auto;
    height: 24px;
    margin-right: 20px;

    & + label {
        display: inline;
    }
}
