@import "../values";

.button {
    display: inline-block;
    color: $text_500;
    font-size: 14px;
    padding: 0 20px;
    height: 50px;
    line-height: 46px;
    cursor: pointer;
    text-decoration: none;
    transition: all .2s ease;
    -webkit-appearance: none !important;
    text-align: center;
    background: $neutral_500;
    border: solid 2px $neutral_600;
    border-radius: 8px;

    &:hover {
        color: $text_700;
        background: $neutral_800;
        text-decoration: none;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 2px rgba($text_500, .6);
        color: $text_800;
        background: $neutral_900;
    }

    .material-icons {
        font-size: 13px;
        margin-right: 8px;
        color: $text_700;
    }

    &.primary {
        background: $primary_600;
        color: $text_inv_500;
        border-color: $primary_200;

        .material-icons {
            color: $text_inv_700;
        }

        &:hover {
            background: $primary_800;
        }

        &:focus {
            color: $text_inv_800;
            background: $primary_900;
        }
    }

    &.secondary {
        background: $accent_600;
        border-color: $accent_200;
        color: $text_inv_600;

        .material-icons {
            color: $text_inv_700;
        }

        &:hover {
            background: $accent_800;
            color: $text_inv_800;
        }

        &:focus {
            background: $accent_900;
            color: $text_inv_800;
        }
    }
}
