// Colours
$primary_500: #32a0ff;
$primary_400: lighten($primary_500, 4);
$primary_300: lighten($primary_500, 8);
$primary_200: lighten($primary_500, 16);
$primary_100: lighten($primary_500, 32);
$primary_600: darken($primary_500, 4);
$primary_700: darken($primary_500, 8);
$primary_800: darken($primary_500, 12);
$primary_900: darken($primary_500, 18);

$accent_500: #f1911d;
$accent_400: lighten($accent_500, 2);
$accent_300: lighten($accent_500, 4);
$accent_200: lighten($accent_500, 8);
$accent_100: lighten($accent_500, 16);
$accent_600: darken($accent_500, 2);
$accent_700: darken($accent_500, 4);
$accent_800: darken($accent_500, 8);
$accent_900: darken($accent_500, 16);

$neutral_500: #eee;
$neutral_100: lighten($neutral_500, 8);
$neutral_200: lighten($neutral_500, 6);
$neutral_300: lighten($neutral_500, 4);
$neutral_400: lighten($neutral_500, 2);
$neutral_600: darken($neutral_500, 2);
$neutral_700: darken($neutral_500, 4);
$neutral_800: darken($neutral_500, 6);
$neutral_900: darken($neutral_500, 8);

$text_500: #212121;
$text_100: lighten($text_500, 48);
$text_200: lighten($text_500, 32);
$text_300: lighten($text_500, 16);
$text_400: lighten($text_500, 8);
$text_600: darken($text_500, 2);
$text_700: darken($text_500, 4);
$text_800: darken($text_500, 6);
$text_900: darken($text_500, 8);

$text_inv_500: #fff;
$text_inv_600: darken($text_inv_500, 2);
$text_inv_700: darken($text_inv_500, 4);
$text_inv_800: darken($text_inv_500, 6);
$text_inv_900: darken($text_inv_500, 8);

// Fonts
$font_primary: 'Rubik', sans-serif;

// Mixins
@mixin clearfix {
    &::after {
        content: " ";
        display: block;
        height: 0;
        clear: both;
    }
}

// Breakpoints
$break_md: 1000px;
$break_lg: 1400px;

// Sizing
$header_height_sm: 50px;
$header_height_md: 60px;

$logo_height_sm: 18px;
$logo_height_md: 22px;

$search_height_sm: 30px;
$search_height_md: 40px;
